import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import PimsImportPatient, { PimsImportPatientParent } from '../pims/ImportPatient'
import { casesSelector, CasesState, fetchCaseAction, uploadAiReportToPimsAction } from '../../hasura/slices/cases'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { insertPimsPatientAction } from '../../hasura/slices/patients'

// @ts-ignore
import checkmark from '../../lib/images/checkmark-green.svg'

interface Props {
  hasPimsOrganization: boolean
  hasPimsPatient: boolean
  setDidEdit: (didEdit: boolean) => void
}

export default function PimsWorkflow(props: Props) {
  const dispatch = useDispatch()

  const { radimalCase }: CasesState = useSelector(casesSelector)
  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const { hasPimsOrganization } = props

  const [isNetworking, setIsNetworking] = useState(false)

  if (!user || !radimalCase || !hasPimsOrganization) return null

  const publishToPims = async () => {
    setIsNetworking(true)
    props.setDidEdit(true)
    await dispatch(uploadAiReportToPimsAction(radimalCase.id))
    await dispatch(fetchCaseAction(accessToken, radimalCase.id, false))
    setIsNetworking(false)
  }

  return (
    <div className="d-flex align-items-center">
      <PimsImportPatient
        isNetworking={isNetworking}
        parent={PimsImportPatientParent.AiAssessment}
        patient={radimalCase.patient}
        publishToPims={publishToPims}
        user={user}
        update={async (patient: any, _?: string) => {
          setIsNetworking(true)
          await dispatch(insertPimsPatientAction(accessToken, { patient_id: radimalCase.patient.id, vetspire_patient_id: patient.id }))
          publishToPims()
        }}
      />
      {isNetworking && <Spinner className="ml-1" color="primary" size="sm" />}
      {!isNetworking && radimalCase.initial_assessment_sent_to_pims && <img className="icon-s ml-1" src={checkmark} />}
    </div>
  )
}
